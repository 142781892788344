import { createSlice } from "@reduxjs/toolkit";

const initSlice = createSlice({
  name: "init",
  initialState: true,
  reducers: {
    setInit(_state, action) {
      return action.payload;
    },
  },
});

export const { setInit } = initSlice.actions;
export default initSlice.reducer;
