import { createSlice } from "@reduxjs/toolkit";

const jwtTokenSlice = createSlice({
  name: "jwt_token",
  initialState: localStorage.getItem("jwt_token"),
  reducers: {
    setJwtToken(_state, action) {
      return action.payload;
    },
  },
});

export const { setJwtToken } = jwtTokenSlice.actions;
export default jwtTokenSlice.reducer;
