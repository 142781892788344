import React from "react";
import { Link, useLocation } from "react-router-dom";
import NavbarAccount from "./NavbarAccount";
import { Dropdown } from "react-bootstrap";

const Navbar = () => {
  let location = useLocation();

  return (
    <nav
      className="navbar navbar-expand-lg bg-primary w-100 color mt-0  navbar-top"
      data-bs-theme="dark"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          Pro.run
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
                aria-current="page"
                href="/"
              >
                Accueil
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname.indexOf("/athletes") !== -1 ? "active" : ""
                }`}
                href="/athletes"
              >
                Athlètes
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname.indexOf("/competitions") !== -1
                    ? "active"
                    : ""
                }`}
                href="/competitions"
              >
                Compétitions
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  location.pathname.indexOf("/clubs") !== -1 ? "active" : ""
                }`}
                href="/clubs"
              >
                Clubs
              </a>
            </li>
            <Dropdown className="nav-item">
              <Dropdown.Toggle className="nav-link">Outils</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="/tools/pace-calculator">Calculateur d'allure</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/tools/race-prediction">Prédiction de courses</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <NavbarAccount />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
