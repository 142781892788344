import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { setUser } from "../../redux/features/userSlice";
import { setJwtToken } from "../../redux/features/jwtTokenSlice";
import { setInit } from "../../redux/features/initSlice";
import CompleteUserProfileModal from "./CompleteUserProfileModal";

export default function UserStateComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const jwt_token = useSelector((state) => state.jwt_token);

  const init = useSelector((state) => state.init);

  const [lastPath, setLastPath] = useState("/");

  useEffect(() => {
    if (searchParams.get("jwt_token")) {
      localStorage.setItem("jwt_token", searchParams.get("jwt_token"));
      dispatch(setJwtToken(searchParams.get("jwt_token")));
      searchParams.delete("jwt_token");
      setSearchParams(searchParams);
      window.location.reload();
    }
  }, [searchParams, setSearchParams, dispatch]);

  useEffect(() => {
    if (location.pathname !== "/login" && location.pathname !== lastPath) {
      setLastPath(location.pathname);
      sessionStorage.setItem("last_path", location.pathname);
    }
  }, [location, setLastPath, lastPath]);

  const [getMe, { loading: loadingUser, data }] = useLazyQuery(
    gql`
      query {
        me {
          id
          email
          firstname
          lastname
          athlete_id
          club_id
          stats
          athlete_subscriptions_ids
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    if (!jwt_token || data) {
      dispatch(setInit(false));
    }
  }, [jwt_token, data, dispatch]);

  useEffect(() => {
    if (jwt_token) {
      getMe();
    }
  }, [jwt_token, getMe]);

  useEffect(() => {
    if (loadingUser) {
      return;
    }

    dispatch(setUser(data?.me ?? null));

    if (data && data.me) {
      if (location.pathname === "/login") {
        if (
          !lastPath.startsWith("/forgot-password") &&
          !lastPath.startsWith("/reset-password") &&
          !lastPath.startsWith("/onboarding")
        ) {
          navigate(lastPath);
        } else {
          navigate("/");
        }
      }
    }
    if (
      !init &&
      !data?.me &&
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/forgot-password") &&
      !location.pathname.startsWith("/reset-password") &&
      !location.pathname.startsWith("/register") &&
      !location.pathname.startsWith("/onboarding/")
    ) {
      navigate("/login");
    }
  }, [data, dispatch, loadingUser, navigate, lastPath, location, init]);

  return (
    <>
      <CompleteUserProfileModal />
    </>
  );
}
