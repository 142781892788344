import { useState } from "react";
import { AthleteFields } from "./AthleteFields";

export const AthleteFilters = ({ field, filter, setFilter }) => {
  const [fullname, setFullname] = useState(filter.fullname ?? "");
  switch (field) {
    case AthleteFields.lastname:
      return (
        <input
          type="text"
          className="form-control form-control-sm"
          value={filter.lastname ?? ""}
          placeholder="Nom"
          onChange={(e) => setFilter({ ...filter, lastname: e.target.value })}
        />
      );
    case AthleteFields.firstname:
      return (
        <input
          type="text"
          className="form-control form-control-sm"
          value={filter.firstname ?? ""}
          placeholder="Prénom"
          onChange={(e) => setFilter({ ...filter, firstname: e.target.value })}
        />
      );
    case AthleteFields.fullname:
      return (
        <input
          type="text"
          className="form-control form-control-sm"
          value={fullname ?? ""}
          placeholder="Nom complet (au moins 3 caractères)"
          onChange={(e) => {
            setFullname(e.target.value);
            if (e.target.value.length > 3) {
              setFilter({ ...filter, fullname: e.target.value });
            } else if (filter.fullname) {
              setFilter({ ...filter, fullname: undefined });
            }
          }}
        />
      );
    case AthleteFields.club:
      return (
        <input
          type="text"
          className="form-control form-control-sm"
          value={filter.club_name ?? ""}
          placeholder="Club"
          onChange={(e) => setFilter({ ...filter, club_name: e.target.value })}
        />
      );
    default:
      return null;
  }
};
