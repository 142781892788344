import React from "react";

import DefaultContent from "./DefaultContent.js";
import Navbar from "./Navbar";

const DefaultLayout = () => {
  return (
    <div className="default-layout">
      <Navbar />

      <div className="container main-content mt-4">
        <DefaultContent />
      </div>
    </div>
  );
};

export default DefaultLayout;
