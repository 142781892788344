import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice.js";
import jwtTokenSlice from "./features/jwtTokenSlice.js";
import initSlice from "./features/initSlice.js";

export default configureStore({
  reducer: {
    user: userReducer,
    jwt_token: jwtTokenSlice,

    init: initSlice,
  },
});
