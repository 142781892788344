import React, { useCallback, useEffect, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import logo from "../logo.svg";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import { setJwtToken } from "../redux/features/jwtTokenSlice";
import { Link } from "react-router-dom";
import { getApiHost } from "../services/api/ApiUtli";

export default function Login() {
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const [doLogin, { data, loading, error: loginError }] = useMutation(
    gql`
      mutation Mutation($input: AuthInput!) {
        auth(input: $input) {
          jwt_token
          user {
            id

            email
            firstname
            lastname
          }
        }
      }
    `
  );
  useEffect(() => {
    let errors = {};
    let canSubmit = true;
    if (!username) {
      errors.username = "Veuillez saisir votre e-mail";
      canSubmit = false;
    } else if (!username.includes("@")) {
      errors.username = "Veuillez saisir un e-mail valide";
      canSubmit = false;
    }
    if (!password) {
      errors.password = "Veuillez saisir votre mot de passe";
      canSubmit = false;
    }
    setErrors(errors);
    setCanSubmit(canSubmit);
  }, [username, password, setErrors]);

  useEffect(() => {
    if (data && data.auth && data.auth.jwt_token) {
      localStorage.setItem("jwt_token", data.auth.jwt_token);
      dispatch(setJwtToken(data.auth.jwt_token));
      dispatch(setUser(data.auth.user));
    }
    if (loginError) {
      switch (loginError?.message) {
        case "user_not_found":
          setErrors({
            username: "Cet identifiant n'existe pas",
          });

          break;
        default:
          setErrors({
            username: "Identifiant ou mot de passe incorrect",
            password: "Identifiant ou mot de passe incorrect",
          });
          break;
      }
      setValidated(true);
    }
  }, [data, loginError, setValidated, dispatch, setErrors]);

  const submit = useCallback(
    (event) => {
      (async () => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setErrors({});

        try {
          await doLogin({
            variables: {
              input: {
                email: username,
                password: password,
              },
            },
          });
        } catch (error) {
          console.error(error);
        }
      })();
    },
    [doLogin, username, password, setValidated]
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 d-none d-md-block">
          <div
            className="d-flex align-items-center w-100"
            style={{
              minHeight: "100vh",
            }}
          >
            <div className="w-100">
              <img
                src={logo}
                className="app-logo w-100 d-block"
                alt="Easyhpad logo"
                style={{ maxWidth: "300px", margin: "auto" }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 d-md-none">
          <div className="w-100">
            <img
              src={logo}
              className="app-logo w-100 d-block"
              alt="Easyhpad logo"
              style={{ maxWidth: "300px", margin: "auto", height: "100px" }}
            />
          </div>
        </div>
        <div
          className="col-md-6"
          style={{
            backgroundColor: "rgb(249, 249, 250)",
            boxShadow: "0 20px 60px rgba(105, 158, 158, 0.3)",
          }}
        >
          <div
            className="flex-row align-items-center"
            style={{
              display: "flex",
              minHeight: "100vh",
            }}
          >
            <div
              className="w-100"
              style={{ maxWidth: "25rem", margin: "auto" }}
            >
              <h1
                className="w-100 text-center"
                style={{ fontSize: "1.4em", fontWeight: "700" }}
              >
                Se connecter
              </h1>

              <div className="py-3">
                <a
                  className="btn btn-primary w-100"
                  href={`${getApiHost()}/auth/google`}
                >
                  <i className="fa-brands fa-google me-4"></i>
                  Se connecter avec Google
                </a>
              </div>
              <div className="separator my-2">ou</div>
              <form onSubmit={submit}>
                <div className="mb-4 form-field">
                  <label htmlFor="email" className="form-label">
                    E-mail <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (validated && errors.username ? " is-invalid" : "")
                    }
                    id="email"
                    placeholder="E-mail"
                    name="email"
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                    value={username}
                  />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="mb-4 form-field">
                  <label htmlFor="password" className="form-label">
                    Mot de passe<span className="required">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={
                        "form-control" +
                        (validated && errors.password ? " is-invalid" : "")
                      }
                      name="password"
                      value={password}
                      id="password"
                      placeholder="Mot de passe"
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                    <span
                      className="input-group-text"
                      id="password"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <i className="fas fa-eye cursor-pointer"></i>
                    </span>
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="w-100 text-end mb-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading || !canSubmit}
                  >
                    Se connecter
                  </button>
                </div>
                <div className="my-1 text-center">
                  <Link to="/forgot-password">Mot de passe oublié ?</Link>
                </div>
                <div className="my-1 text-center">
                  <Link to="/register">Créer un compte</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
