import { useMutation } from "@apollo/client";
import { MUTATION_ASSIGN_TO_ME } from "./AthleteUtil";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";

export default function AthleteAssignToMeButton({ athlete }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [assign, { loading }] = useMutation(MUTATION_ASSIGN_TO_ME);

  if (loading) return null;

  if (user?.athlete_id === athlete.id) return null;

  return (
    <button
      disabled={loading}
      className="btn btn-primary btn-sm"
      onClick={async () => {
        let result = await assign({ variables: { athlete_id: athlete.id } });
        if (result.data.assign_athlete_to_me) {
          dispatch(
            setUser({
              ...user,
              athlete_id: result.data.assign_athlete_to_me.athlete_id,
              club_id: result.data.assign_athlete_to_me.club_id,
            })
          );
        }
      }}
    >
      C'est moi !
    </button>
  );
}
