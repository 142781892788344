import { gql } from "@apollo/client";
import { formatNumberWithMaxDecimals } from "../../services/common/NumberUtil";

export const QUERY_MY_ATHLETE_SUBSCRIPTION = gql`
  query Query($athlete_id: ID!) {
    my_athlete_subscription(athlete_id: $athlete_id) {
      id
    }
  }
`;
export const MUTATION_SUBSCRIBE_ATHLETE = gql`
  mutation Mutation($athlete_id: ID!) {
    subscribe_athlete(athlete_id: $athlete_id) {
      id
    }
  }
`;

export const MUTATION_UNSUBSCRIBE_ATHLETE = gql`
  mutation Mutation($athlete_id: ID!) {
    unsubscribe_athlete(athlete_id: $athlete_id)
  }
`;

export const MUTATION_ASSIGN_TO_ME = gql`
  mutation Mutation($athlete_id: ID!) {
    assign_athlete_to_me(athlete_id: $athlete_id) {
      id
      club_id
      athlete_id
    }
  }
`;

export const getAthleteQuery = ({ club = false, last_result = false }) => {
  return gql`
    query Query($id: ID!) {
      athlete(id: $id) {
        id
        firstname
        lastname
        scores
        license_number
        ${club ? "club { id name }" : ""}        
        ${
          last_result
            ? `last_result { id official_rank official_time competition_id   athlete_score_history {old_scores new_scores} competition { id name } }`
            : ""
        }
        club_id
     
        stats
      }
    }
  `;
};

export const formatAthleteScore = (athlete) => {
  let score = athlete.scores?.elo_score;
  if (!score) return "-";
  return formatNumberWithMaxDecimals(score, 2);
};
