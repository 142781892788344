import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_SUBSCRIBE_ATHLETE,
  MUTATION_UNSUBSCRIBE_ATHLETE,
  QUERY_MY_ATHLETE_SUBSCRIPTION,
} from "./AthleteUtil";

export default function AthleteSubscriptionButton({ athlete, className }) {
  const { data, loading, refetch } = useQuery(QUERY_MY_ATHLETE_SUBSCRIPTION, {
    variables: { athlete_id: athlete.id },
  });

  const [subscribe, { loading: subscribeLoading }] = useMutation(
    MUTATION_SUBSCRIBE_ATHLETE
  );

  const [unsubscribe, { loading: unsubscribeLoading }] = useMutation(
    MUTATION_UNSUBSCRIBE_ATHLETE
  );

  if (loading) return null;

  if (data && data.my_athlete_subscription) {
    return (
      <button
        disabled={subscribeLoading || unsubscribeLoading}
        className={`btn btn-primary ${className}`}
        onClick={async () => {
          await unsubscribe({ variables: { athlete_id: athlete.id } });
          refetch();
        }}
      >
        Se désabonner
      </button>
    );
  } else {
    return (
      <button
        disabled={subscribeLoading || unsubscribeLoading}
        className={`btn btn-primary ${className}`}
        onClick={async () => {
          await subscribe({ variables: { athlete_id: athlete.id } });
          refetch();
        }}
      >
        S'abonner
      </button>
    );
  }
}
