export const formatNumberWithMaxDecimals = (number, decimals = 2) => {
  if (number === null || number === undefined) {
    return "";
  }
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    return number;
  }
  return parsedNumber.toLocaleString("fr-FR", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  // //on ajoute un espace tous les 3 chiffres
  // value = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // return value;
};

export const formatInteger = (value) => {
  value = parseInt(value);
  if (!isNaN(value)) {
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else {
    value = "";
  }
  return value;
};

export const formatFloat = (value) => {
  if (!isNaN(value)) {
    value = Math.round(value * 100) / 100;
    value = value.toString().replace(".", ",");
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else {
    value = "";
  }
  return value;
};

export const formatEuro = (value, maxDecimals = 2) => {
  return formatNumberWithMaxDecimals(value, maxDecimals) + " €";
};

export const formatPercent = (value) => {
  return formatFloat(value) + " %";
};

export const calculateAndFormatPercent = (a, b) => {
  if (!b) {
    return "";
  }
  return formatPercent(calculatePercent(a, b));
};

export const calculatePercent = (a, b) => {
  if (isNaN(a) || isNaN(b)) {
    return null;
  }
  if (!a || !b) {
    return 0;
  }
  return (100 * a) / b;
};
