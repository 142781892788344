import React from "react";
const Home = React.lazy(() => import("./views/home/Home"));
const Athletes = React.lazy(() => import("./views/athlete/Athletes"));
const Athlete = React.lazy(() => import("./views/athlete/Athlete"));

const Competition = React.lazy(() => import("./views/competition/Competition"));
const Competitions = React.lazy(() =>
  import("./views/competition/Competitions")
);

const Club = React.lazy(() => import("./views/club/Club"));
const Clubs = React.lazy(() => import("./views/club/Clubs"));

const Settings = React.lazy(() => import("./views/settings/Settings"));
const PaceCalculator = React.lazy(() => import("./views/tools/PaceCalculator"));
const RacePrediction = React.lazy(() => import("./views/tools/RacePrediction"));
const routes = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/clubs",
    element: Clubs,
  },
  {
    path: "/clubs/:id",
    element: Club,
  },
  {
    exact: true,
    path: "/athletes",
    element: Athletes,
  },
  {
    path: "/athletes/:id",
    element: Athlete,
  },
  {
    path: "/competitions",
    element: Competitions,
  },
  {
    path: "/competitions/:id",
    element: Competition,
  },
  {
    path: "/settings/:page",
    element: Settings,
  },
  {
    path: "/settings/",
    element: Settings,
  },
  {
    path: "/tools/pace-calculator",
    element: PaceCalculator,
  },
  {
    path: "/tools/race-prediction",
    element: RacePrediction,
  },
];

export default routes;
