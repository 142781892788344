import "./scss/style.scss";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DefaultLayout from "./components/layout/DefaultLayout";
import UserStateComponent from "./components/user/UserStateComponent";
import Login from "./pages/Login";
import { getApiHost } from "./services/api/ApiUtli";
import Register from "./pages/Register";
import Onboarding from "./pages/Onboarding";
import { ToastContainer } from "react-toastify";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          exact: true,
          path: "/login",
          name: "Se connecter",
          element: <Login />,
        },
        {
          exact: true,
          path: "/register",
          name: "S'inscrire",
          element: <Register />,
        },
        {
          exact: true,
          path: "/onboarding/:token",
          name: "Onboarding",
          element: <Onboarding />,
        },
        {
          index: true,
          element: <DefaultLayout />,
        },
        {
          path: "*",
          element: <DefaultLayout />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

const Root = () => {
  const [client, setClient] = useState(getApolloClient());
  const jwt_token = useSelector((state) => state.jwt_token);

  useEffect(() => {
    setClient(getApolloClient(jwt_token));
  }, [jwt_token]);
  return (
    <ApolloProvider client={client}>
      <UserStateComponent />
      <Outlet></Outlet>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ApolloProvider>
  );
};

export default App;

const getApolloClient = (jwtToken) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: `${getApiHost()}/graphql`,
      headers: {
        authorization: jwtToken ? `Bearer ${jwtToken}` : "",
      },
    }),
  });
};
