export const AthleteFields = Object.freeze({
  fullname: "fullname",
  elo_score: "elo_score",
  category: "category",
  firstname: "firstname",
  lastname: "lastname",
  gender: "gender",
  dob: "dob",
  club: "club",
  rank: "rank",
  action_assign_to_user: "action_assign_to_user",
  action_subscribe: "action_subscribe",
});

export const AthleteFieldsLabels = Object.freeze({
  [AthleteFields.fullname]: "Nom",
  [AthleteFields.elo_score]: "Score",
  [AthleteFields.category]: "Catégorie",
  [AthleteFields.firstname]: "Prénom",
  [AthleteFields.lastname]: "Nom",
  [AthleteFields.gender]: "Sexe",
  [AthleteFields.dob]: "Date de naissance",
  [AthleteFields.club]: "Club",
  [AthleteFields.rank]: "",
  [AthleteFields.action_assign_to_user]: "",
});
