import { Link } from "react-router-dom";

import { AthleteFields } from "./AthleteFields";
import { formatAthleteScore } from "./AthleteUtil";
import { formatInteger } from "../../services/common/NumberUtil";
import AthleteAssignToMeButton from "./AthleteAssignToMeButton";
import AthleteSubscriptionButton from "./AthleteSubscriptionButton";

export const AthleteValues = ({ athlete, field }) => {
  switch (field) {
    case AthleteFields.fullname:
      return (
        <Link to={`/athletes/${athlete.id}`}>
          {athlete.firstname} {athlete.lastname}
        </Link>
      );
    case AthleteFields.club:
      return (
        <Link to={`/clubs/${athlete.club?.id}`}>{athlete.club?.name}</Link>
      );
    case AthleteFields.category:
      return athlete.category;

    case AthleteFields.rank:
      return formatInteger(athlete._rank);
    case AthleteFields.elo_score:
      return formatAthleteScore(athlete);

    case AthleteFields.action_assign_to_user:
      return <AthleteAssignToMeButton athlete={athlete} />;
    case AthleteFields.action_subscribe:
      return <AthleteSubscriptionButton athlete={athlete} className="btn-sm" />;
    default:
      return null;
  }
};
