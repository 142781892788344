import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import AthletesTable from "../athlete/AthletesTable";
import { AthleteFields } from "../athlete/AthleteFields";
import { useEffect, useState } from "react";

export default function CompleteUserProfileModal() {
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [fullname, setFullname] = useState("");
  useEffect(() => {
    setShow(user?.athlete_id === null);
  }, [user]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Compléter votre profil"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-3">
        <div className="alert alert-secondary">
          Vous n'avez pas encore lié votre profil à un athlète. Vous pouvez le
          faire en cliquant en recherchant votre profil d'athlète, et en le
          sélectionnant.
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher votre profil d'athlète"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
        </div>
        <div style={{ height: "400px" }}>
          {fullname.length > 3 && (
            <AthletesTable
              fields={[
                AthleteFields.fullname,
                AthleteFields.club,
                AthleteFields.category,
                AthleteFields.action_assign_to_user,
              ]}
              showHeader={false}
              showFilters={false}
              rows={10}
              defaultFilter={{ fullname }}
              showPagination={true}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
