import React, { useCallback, useEffect } from "react";

import { gql, useMutation } from "@apollo/client";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../views/common/Loader";

import { setJwtToken } from "../redux/features/jwtTokenSlice";
import { setUser } from "../redux/features/userSlice";
import Error from "../views/common/Error";

export default function Onboarding() {
  const { token } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("jwt_token");
  });
  //on vérifie que le token est valide
  const [
    doAuthWithToken,
    { data: dataAuth, loading: authLoading, error: authError },
  ] = useMutation(
    gql`
      mutation Mutation($token: String!) {
        auth_with_token(token: $token) {
          jwt_token
          user {
            id
            email
          }
        }
      }
    `
  );
  const doAuth = useCallback(
    async (tokenToUse) => {
      try {
        await doAuthWithToken({ variables: { token: tokenToUse } });
      } catch (error) {
        console.error(error);
      }
    },
    [doAuthWithToken]
  );

  useEffect(() => {
    if (token) {
      doAuth(token);
    }
  }, [token, doAuth]);

  useEffect(() => {
    if (
      dataAuth &&
      dataAuth.auth_with_token &&
      dataAuth.auth_with_token.jwt_token
    ) {
      localStorage.setItem("jwt_token", dataAuth.auth_with_token.jwt_token);
      dispatch(setJwtToken(dataAuth.auth_with_token.jwt_token));
      dispatch(setUser(dataAuth.auth_with_token.user));
      navigate("/");
    }
  }, [dataAuth, dispatch, navigate]);
  if (authLoading) {
    return <Loader />;
  }
  if (authError) {
    return (
      <Error
        title="Lien invalide"
        message="Le lien que vous avez utilisé n'est plus valide. Veuillez vérifier que vous avez bien copié le bon lien."
      />
    );
  }
  return null;
}
