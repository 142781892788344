import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setJwtToken } from "../../redux/features/jwtTokenSlice";
import { setUser } from "../../redux/features/userSlice";

const NavbarAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  if (!user) {
    return null;
  }
  return (
    <div className="dropdown  dropdown-profile">
      <div
        className="nav-link dropdown-toggle"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa-solid fa-circle-user fs-2 text-white"></i>
      </div>

      <ul className="dropdown-menu dropdown-menu-end">
        <li>
          <Link className="dropdown-item" to="/settings/">
            Paramètres
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Link
            className="dropdown-item"
            to="/"
            onClick={() => {
              localStorage.removeItem("jwt_token");
              dispatch(setJwtToken(null));
              dispatch(setUser(null));
              navigate("/");
            }}
          >
            Se déconnecter
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavbarAccount;
