import React, { useCallback } from "react";
import { formatInteger } from "../../services/common/NumberUtil";

const DEFAULT_LIMITS = [1, 10, 50, 100, 200, 500];

export default function PaginationUtil({
  offset,
  limit,
  total,
  setOffset,
  setLimit,
  limits,
  showSelectPageSection,
}) {
  const currentPage = Math.floor(offset / limit) + 1;
  const pages =
    Math.floor(total > 0 && limit > 0 ? (total - 1) / limit : 0) + 1;

  if (!limits) {
    limits = DEFAULT_LIMITS;
  }

  const pageChange = useCallback(
    (page) => {
      page = Math.min(pages, page);
      page = Math.max(1, page);
      let newOffset = (page - 1) * limit;
      setOffset(newOffset);
    },
    [setOffset, pages, limit]
  );
  if (total === 0) {
    return null;
  }
  let paginationItems = [];
  if (currentPage > 3) {
    paginationItems.push(
      <li className="page-item" key="first">
        <button className="btn-link page-link" onClick={() => pageChange(1)}>
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
    );
  }
  if (currentPage > 1) {
    paginationItems.push(
      <li className="page-item" key="prev">
        <button
          className={"btn-link page-link"}
          onClick={() => pageChange(currentPage - 1)}
        >
          Précédent
        </button>
      </li>
    );
    if (currentPage > 3) {
      paginationItems.push(
        <li className="page-item" key="first_ellipsis">
          <button className={"btn-link page-link"}>...</button>
        </li>
      );
    }
  }
  for (let i = 1; i <= pages; i++) {
    if (i > currentPage - 3 && i < currentPage + 3) {
      paginationItems.push(
        <li className="page-item" key={i}>
          <button
            className={`btn-link page-link ${
              i === currentPage ? "active" : ""
            }`}
            onClick={() => pageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }
  }
  if (currentPage < pages) {
    if (currentPage <= pages - 3) {
      paginationItems.push(
        <li className="page-item" key="next_ellipsis">
          <button className={"btn-link page-link"}>...</button>
        </li>
      );
    }
    paginationItems.push(
      <li className="page-item" key="next">
        <button
          className="btn-link page-link"
          onClick={() => pageChange(currentPage + 1)}
        >
          Suivant
        </button>
      </li>
    );
  }
  if (currentPage <= pages - 3) {
    paginationItems.push(
      <li className="page-item" key="last">
        <button
          className="btn-link page-link"
          onClick={() => pageChange(pages)}
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    );
    // paginationItems.push(<Pagination.Ellipsis key={"last_ellipsis"} />);
    // paginationItems.push(
    //   <Pagination.Last key={"last"} onClick={() => pageChange(pages)} />
    // );
  }

  return (
    <div className="row">
      <div className="col-md-4">
        {showSelectPageSection && pages > 1 && (
          <div className="d-flex justify-content-start">
            <div className="d-inline-block ms-2">
              <span>Aller à</span>

              <input
                type="number"
                style={{ width: "80px" }}
                className="form-control form-control-sm d-inline-block mx-2 "
                value={currentPage}
                onChange={(event) => {
                  pageChange(parseInt(event.target.value));
                }}
              />

              <span> sur {pages}</span>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-8">
        <div className="d-flex justify-content-end">
          {total >= 1 && setLimit && (
            <div className="d-inline-block ms-2">
              <span>Afficher</span>

              <select
                style={{ width: "80px" }}
                className="form-select form-select-sm d-inline-block ms-2"
                value={limit}
                onChange={(event) => {
                  setLimit(parseInt(event.target.value));
                }}
              >
                {limits.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="d-inline-block ms-2 pt-1">
            <span>
              {offset + 1}-{Math.min(total, offset + limit)} sur{" "}
              {formatInteger(total)}
            </span>
          </div>
          {paginationItems.length >= 1 && (
            <ul className="pagination pagination-sm ms-2">{paginationItems}</ul>
          )}
        </div>
      </div>
    </div>
  );
}
