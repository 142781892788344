import React from "react";

export default function Error({ title, message }) {
  return (
    <div
      className="flex-row align-items-center"
      style={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <div className=" w-100">
        <div
          className="card border-danger"
          style={{ maxWidth: "500px", margin: "auto", border: "1px solid" }}
        >
          <div className="card-body">
            <h1>
              <i className="fa-solid fa-circle-exclamation me-4"></i>
              {title}
            </h1>
            {message && <p>{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
