import { gql, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import PaginationUtil from "../common/PaginationUtil";

import Loader from "../common/Loader";

import { AthleteFields, AthleteFieldsLabels } from "./AthleteFields";
import { AthleteValues } from "./AthleteValues";
import { AthleteFilters } from "./AthleteFilters";

const GET_ATHLETES = gql`
  query Query($filter: JSON, $offset: Int, $limit: Int, $sort: [[String]]) {
    athletes(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      total
      items {
        id
        lastname
        firstname
        scores
        category
        gender
        dob
        club {
          id
          name
        }
      }
    }
  }
`;

export default function AthletesTable({
  defaultFilter,
  rows = 100,
  showPagination = true,
  showFilters = true,
  showHeader = true,
  fields = [
    AthleteFields.fullname,
    AthleteFields.club,
    AthleteFields.elo_score,
  ],
  responsive = true,
}) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(rows);
  const [filter, setFilter] = useState({});

  const { data, loading } = useQuery(GET_ATHLETES, {
    variables: {
      limit: limit,
      offset: offset,
      filter: filter,
      sort: [["scores.elo_score", "DESC"]],
    },
  });
  useEffect(() => {
    if (defaultFilter) {
      setFilter(defaultFilter);
    }
  }, [defaultFilter]);

  let athletes = JSON.parse(JSON.stringify(data?.athletes?.items ?? []));
  athletes = athletes.map((athlete, index) => {
    athlete._rank = index + 1 + offset;
    return athlete;
  });
  return (
    <div>
      <div className={responsive ? "table-responsive" : "w-100"}>
        <table
          className="table table-striped table-sm"
          style={{ tableLayout: !responsive ? "fixed" : "auto" }}
        >
          {showHeader && (
            <thead>
              <tr>
                {fields.map((field) => (
                  <th key={field}>{AthleteFieldsLabels[field]}</th>
                ))}
              </tr>
            </thead>
          )}

          {showFilters && (
            <tbody>
              <tr>
                {fields.map((field) => (
                  <td key={field}>
                    <AthleteFilters
                      field={field}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          )}
          {loading && (
            <tbody>
              <tr>
                <td colSpan={4} className="p-4">
                  <Loader />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody>
              {athletes.map((athlete) => (
                <tr key={athlete.id}>
                  {fields.map((field) => (
                    <td key={field} style={AthleteTdStyles[field]}>
                      <div
                        className="text-nowrap"
                        style={AthleteCellStyles[field]}
                      >
                        <AthleteValues athlete={athlete} field={field} />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {showPagination && (
        <div>
          {data?.athletes?.total > limit && (
            <PaginationUtil
              offset={offset}
              limit={limit}
              total={data?.athletes?.total}
              setOffset={setOffset}
              setLimit={setLimit}
            />
          )}
        </div>
      )}
    </div>
  );
}

export const AthleteTdStyles = Object.freeze({
  [AthleteFields.fullname]: {
    width: "100%",
  },
  [AthleteFields.elo_score]: {
    width: "80px",
  },
  [AthleteFields.rank]: {
    width: "20px",
  },
});

export const AthleteCellStyles = Object.freeze({
  [AthleteFields.fullname]: {
    width: "100%",
    maxWidth: "300px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [AthleteFields.elo_score]: {
    width: "80px",
  },
  [AthleteFields.rank]: {
    width: "20px",
  },
});
