import React from "react";

export default function Loader() {
  return (
    <div
      className="flex-row align-items-center"
      style={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="spinner-grow text-secondary" role="status">
          <span className="visually-hidden">Chargement ...</span>
        </div>
      </div>
    </div>
  );
}
