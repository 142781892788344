import React, { Suspense } from "react";
import routes from "../../routes.js";
import { Route, Routes } from "react-router-dom";
import Loader from "../common/Loader";

export const DefaultContent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <main className={route.main_class ?? ""}>
                    <route.element />{" "}
                  </main>
                }
              />
            )
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default DefaultContent;
